<template>
  <div v-if="currentUser" class="subtitle">
    <div v-if="loading">
      <div class="subtitle blink"> Witamy : {{ currentUser.userName }} lista aktualnych szkoleń jest przygotowywana .  .  .</div>
    </div>
    <div v-else>
      <h3> Witamy : {{ currentUser.userName }}</h3>
    </div>
  </div>
  <section class="articles">
    <tr v-for="cont in content" :key="cont.id">
      <article>
        <div class="article-wrapper">
          <figure>
            <img :src="getIMGPath('img' + cont.statusId + '.png')" alt="" />
          </figure>
          <div class="article-body">
            <status>{{ cont.status }}</status>
            <h4>{{ cont.title }}</h4>
            <div class="badges">
              <div v-if="cont.days > 0" class="days">
                <p>Pozostało jeszcze : {{ cont.days }} dni</p>
              </div>
              <div v-else>
                <p>Zakończono :</p>
              </div>
              <div class="date">
                <p>|</p>
              </div>
              <div class="date">
                <p>{{ cont.dateS }}</p>
              </div>
            </div>
            <hr>
            <h6>Ukończono {{ cont.progress }}%</h6>
            <progress :value=cont.progress min="0" max="100"></progress>
            <button class="button button23" @click="getToDoItem(cont)">{{ cont.button }}</button>
          </div>
        </div>
      </article>
    </tr>
  </section>
</template>

<script>
import UserService from "../services/user.service";
export default {
  name: "User",
  data() {
    return {
      loading: false,
      content: "",
    };
  },
  methods: {
    getIMGPath(img) {
      return img ? require("@/assets/images/status/" + img) : "";
    },
    getToDoItem(rowItem) {
      this.$store.userToDo = rowItem;
      this.$router.push('/todoitem');
    }
  },
  computed: {
    currentUser() {//console.log (this.$store.state.auth.user)
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    //var dupa = this.currentUser; console.log (dupa)
    this.loading = true;
    setTimeout(() =>{
      if (this.currentUser) {
      UserService.getUserBoard(this.$store.state.auth.user.claims.id).then(
        (response) => {
          this.loading = false;
          this.content = response.data;
        },
        (error) => {
          this.loading = false;
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
    },0)
    
  },
}
</script>
<!-- <script>
import UserService from "../services/user.service";

export default 
{   computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  name: "User",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getUserBoard().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script> -->
