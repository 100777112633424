<template>
      <div class="footer">
      <footer class="footer-distributed">
        <div class="footer-right">
          <a class="watch"> Obserwuj nas :</a>
          <a href=""><img src="../assets/Instagram.svg" alt="Instagram"></a>
          <a href=""><img src="../assets/LinkedIn.svg" alt="Linkedin"> </a>
          <a href=""><img src="../assets/FaceBook.svg" alt="Facebook"> </a>
           <!-- <a href=""><img src="../assets/YouTube.svg" alt="You Tube"> </a>  -->
          <a class="link-you" href=""><img src="../assets/YouTube.svg" alt="You Tube"> </a>
        </div>
        <div class="footer-left">
          <p class="footer-links">
            <a class="link-1" href=""><router-link to="/">Głowna</router-link></a>
            <a href="">Polityka cookies</a>
            <a href="">Mapa strony</a>
            <a href="">&copy; Company 2023</a>
          </p>
        </div>
      </footer>
    </div>
</template>
