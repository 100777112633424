<!-- <img src="@/assets/img7.png" alt="Ekspert ds. BHP" /> #F8FAFA font-weight: bold; #1123FF-->
<template>
  <div>
    <div class="contact-us">Skontaktuj się z nami</div>
    <!-- <div class="line"></div> -->
    <div class="panels">
      <div v-for="(panel, index) in panels" :key="index" class="panel">
        <h1>{{ panel.title }}</h1>
        <hr v-if="panel.imageUrl" />
        <p v-if="panel.imageUrl">Twój Ekspert ds. BHP to:</p>
        <div v-if="panel.imageUrl" class="image-container">
          <img src="@/assets/img7.png" alt="Ekspert ds. BHP" />
          <!-- <img :src="panel.imageUrl" alt="Ekspert ds. BHP" /> -->
        </div>
        <h2 v-if="panel.expert">{{ panel.expert }}</h2>
        <p>{{ panel.description }}</p>
        <hr />
        <p>{{ panel.contactMessage }}</p>
        <p>{{ panel.phone }}</p>
        <p><a :href="'mailto:' + panel.email">{{ panel.email }}</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BhpPanel",
  data() {
    return {
      panels: [
        {
          title: "Dział BHP",
          imageUrl: "./assets/your-image.jpg",
          expert: "Andrzej Bączkowski, Specjalista ds. BHP",
          description: "Masz pytania dotyczące tematyki szkolenia i potrzebujesz konsultacji ze Specjalistą ds. BHP?",
          contactMessage: "Skontaktuj się z Działem BHP:",
          phone: "+48669600217",
          email: "a.baczkowski@wwconsulting.pl"
        },
        {
          title: "Dział Szkoleń",
          imageUrl: null,
          expert: null,
          description: "Masz pytania organizacyjne bądź potrzebujesz pomocy technicznej?",
          contactMessage: "Skontaktuj się z Działem Szkoleń:",
          phone: "+48669600217",
          email: "szkolenia@wwconsulting.pl"
        }
      ]
    };
  }
};
</script>

<style scoped>
.contact-us {
  font-size: 26px;
  font-weight: bold;
  color: black;
  text-align: center;
  margin: 10px 0;
}

.line {
  width: 100%;
  max-width: 860px; /* 400px (panel width) * 2 + 20px (gap) */
  height: 1px;
  background-color: grey;
  margin: 0 auto 20px;
}

.panels {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.panel {
  max-width: 400px;
  margin: 10px;
  /* margin-left: 60px;
  margin-right: 60px; */
  padding: 20px 60px 20px 60px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
  background: #F8FAFA;
}

h1 {
  color: #1123FF;
  font-size: 24px;
}

hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  margin: 10px auto;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
}

img {
  width: 100%;
  height: auto;
}

h2 {
  font-size: 18px;
  font-weight: bold;
}

p {
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

a {
  color: #1123FF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 2800px) {
  .line {
    max-width: calc(4 * 398px + 5 * 20px); /* 4 kolumny paneli i 2 odstępy między nimi */
  }
}

@media (max-width: 1700px) {
  .line {
    max-width: calc(3 * 398px + 4 * 20px); /* 3 kolumny paneli i 2 odstępy między nimi */
  }
}

@media (max-width: 1300px) {
  .line {
    max-width: calc(2 * 398px + 2 * 20px); /* 2 kolumny paneli i 1 odstęp między nimi */
  }
}

@media (max-width: 768px) {
  .line {
    display: none;
  }
  .panels {
    flex-direction: column;
    align-items: center;
  }
}
</style>