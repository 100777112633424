<template>
  <Form @submit="handleEmail" :validation-schema="schema">
    <div class="loginform">
      <div v-if="!successful">
        <div class="subtitle">Zaloguj się poprzez emaila</div>
        <div class="input-container ic2">
          <!-- <input id="email" class="input" type="text" placeholder=" "/> -->
          <Field id="email" name="email" type="text" class="input" placeholder=" " />
          <div class="cut"></div>
          <label for="email" class="placeholder">Wpisz swój adres e-mail:</label>
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <div v-if="loading">
          <button type="submit" class="submit blink">Trwa logowanie . . .</button>
        </div>
        <div v-else>
          <button type="submit" class="submit">Zaloguj się</button>
        </div>
        <h5><br></h5>
        <div class="form-group">
          <div v-if="message">
            {{ message }}
          </div>
        </div>
        <h5><br></h5>
        <div class="l11">lub</div>
        <router-link to="/login" class="btn">Zaloguj się klasycznie</router-link>
        <h5><br></h5>
        <h5><br></h5>
        <div class="l11">lub</div>
        <router-link to="/register" class="btn">Zarejestruj się</router-link>
      </div>
      <div v-if="successful">
        <div class="login-container">
          <h2>Zaloguj się przez e-mail</h2>
          <p class="email-check">Sprawdź swoją skrzynkę e-mail:</p>

          <div class="email-input">
            <input type="text" v-model=message.email readonly />
          </div>

          <p class="instruction">
            Jeżeli wprowadzony adres e-mail jest zgodny z naszymi danymi, wkrótce otrzymasz wiadomość zawierającą
            jednorazowy link do logowania.
          </p>
          <p class="instruction">
            Link do logowania będzie aktywny tylko 24 godziny. Po tym czasie trzeba ponownie przejść procedure logowania
            poprzez e-mail.
          </p>
          <p class="spam-check">
            Nie widzisz wiadomości mailowej? Sprawdź folder SPAM lub
            <a href="#" @click="refreshPage">wyślij link ponownie</a>.
          </p>
          <!-- <router-link to="/loginbyemail" class="spam-check">
            wyślij link ponownie
          </router-link>
          <button @click="refreshPage">wyślij link ponownie ref</button> -->
          <div class="divider">
            <span>lub</span>
          </div>

          <router-link to="/login" class="password-login-button">
            Zaloguj się hasłem
          </router-link>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Email",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Wprowadź e-mail !"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    refreshPage() {
      // this.$router.go()
      this.message = "";
      this.successful = false;
    },
    handleEmail(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      this.$store.dispatch("auth/email", user).then(
        (data) => {
          this.message = data;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 568px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.email-check {
  font-weight: bold;
  margin-bottom: 10px;
}

.email-input {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.email-input input {
  width: 568px;
  height: 74px;
  padding: 10px;
  background: #F8FAFA 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 22px;
  opacity: 1;
  border: none;
  text-align: center;
  cursor: not-allowed;
  font-size: 1.0em; /* Zmieniono na 1.0em */
}

.instruction {
  margin: 15px 0;
  /* Usunięto rozmiar czcionki, domyślny rozmiar będzie użyty */
}

.spam-check {
  
}

.spam-check a {
  color: #007BFF;
  text-decoration: none;
}
.spam-check a:hover {
  text-decoration: none;
  text-decoration: underline;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 536px;
  height: 21px;
  margin: 20px auto;
  position: relative;
  opacity: 1;
  color: #89979A;
}

.divider::before,
.divider::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: #89979A;
  margin: 0 10px;
}

.password-login-button {
  display: inline-block;
  width: 536px;
  height: 46px;
  line-height: 46px;
  color: #1124FF;
  background-color: #FFFFFF;
  border: 1px solid #1124FF;
  border-radius: 23px;
  opacity: 1;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.password-login-button:hover {
  background-color: #f0f0f0; /* Zmiana tła przy najechaniu */
}

/* Responsive Design */
@media (max-width: 768px) {
  .login-container {
    padding: 15px;
  }

  .email-input input {
    width: 100%;
  }

  .password-login-button {
    width: 100%;
  }

  .divider {
    width: 100%; /* Ustawiamy szerokość na 100% na urządzeniach mobilnych */
  }
}
.error-feedback {
  color: red;
  margin-left: 100px;
}

.feedback {
  text-align: justify;
}
</style>
