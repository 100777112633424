import axios from 'axios';
import authHeader from './auth-header';
// http://localhost:5239/Company http://localhost:8080/api/test/ http://localhost:5239/Employee https://one.apiai.pl/api/api/Authentication/
const API_URL = process.env.VUE_APP_BASE_API_URL;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard(itemId) {
    return axios.get(API_URL+'ToDoTree'+'/'+itemId, { headers: authHeader() });
    // return axios.get(API_URL + 'user', { headers: authHeader() });
  }
  getModeratorBoard() {
    return axios.get(API_URL +'ToDoTree/1/1', { headers: authHeader() });
  }
  getAdminBoard() {
    return axios.get(API_URL+'Employee', { headers: authHeader() });
  }
  getWorkBoard(userId, itemId) {
    return axios.get(API_URL +'ToDoTree'+'/'+userId+'/'+itemId+'/'+itemId, { headers: authHeader() });
  }
  getEmployee() {
    return axios.get(API_URL +'Employee', { headers: authHeader() });
  }
  getCompany() {
    return axios.get(API_URL +'Company', { headers: authHeader() });
  }
  getCourse() {
    return axios.get(API_URL +'OnlineCourse', { headers: authHeader() });
  }
}

export default new UserService();
