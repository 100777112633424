<template>
  <Form @submit="handleRegister" :validation-schema="schema">
    <div class="regform">
      <div v-if="!successful">
        <div v-show="step === 1">
          <progress max="100" value="50"></progress>
          <div class="subtitle">Zarejestruj się krok pierwszy</div>
          <div class="info">Wszystkie pola wymagane</div>
          <div class="input-container ic2">
            <Field id="email" name="email" type="text" class="input" placeholder="" />
            <div class="cut"></div>
            <label for="email" class="placeholder">Wpisz swój adres e-mail:</label>
            <ErrorMessage name="email" class="error-feedback" />
          </div>
          <div class="statute">Ten adres mailowy będzie jednocześnie Twoją nazwą użytkownika / loginem.</div>
          <div class="input-container ic2">
            <Field id="username" name="username" type="text" class="input" placeholder="" />
            <div class="cut cut-short"></div>
            <label for="username" class="placeholder">Imię:</label>
            <ErrorMessage name="username" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field id="lastname" name="lastname" type="text" class="input" placeholder="" />
            <div class="cut cut-mid"></div>
            <label for="lastname" class="placeholder">Nazwisko:</label>
            <ErrorMessage name="lastname" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field name="password" type="password" class="input" placeholder="" />
            <div class="cut cut-short"></div>
            <label for="password" class="placeholder">Hasło:</label>
            <ErrorMessage name="password" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field name="password2" type="password" class="input" placeholder="" />
            <div class="cut cut-mid2"></div>
            <label for="password2" class="placeholder">Powtórz hasło:</label>
            <ErrorMessage name="password2" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field id="borndate" name="borndate" type="date" class="input" placeholder="" />
            <div class="cut cut-long"></div>
            <label for="borndate" class="placeholder">Data urodzenia:</label>
            <ErrorMessage name="borndate" class="error-feedback" />
          </div>
          <div class="nextprev">
            <div class="btn">
              <div @click.prevent="next()">Następny krok</div>
            </div>
          </div>
        </div>
        <div v-show="step === 2">
          <progress max="100" value="100"></progress>
          <div class="subtitle">Zarejestruj się krok ostatni</div>
          <div class="info">Wszystkie pola wymagane</div>
          <div class="input-container ic2">
            <Field id="company" name="company" type="text" class="input" placeholder="" />
            <div class="cut cut-short"></div>
            <label for="company" class="placeholder">Firma:</label>
            <ErrorMessage name="company" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field id="taxnumber" name="taxnumber" type="text" class="input" placeholder="" />
            <div class="cut cut-short"></div>
            <label for="taxnumber" class="placeholder">NIP:</label>
            <ErrorMessage name="taxnumber" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field name="address" type="text" class="input" placeholder="" />
            <div class="cut cut-long"></div>
            <label for="address" class="placeholder">Ulica i numer:</label>
            <ErrorMessage name="address" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field name="zipcode" type="text" class="input" placeholder="" />
            <div class="cut cut-long"></div>
            <label for="zipcode" class="placeholder">Kod pocztowy:</label>
            <ErrorMessage name="zipcode" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field name="city" type="text" class="input" placeholder="" />
            <div class="cut cut-mid2"></div>
            <label for="city" class="placeholder">Miejscowość:</label>
            <ErrorMessage name="city" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field name="position" type="text" class="input" placeholder="" />
            <div class="cut cut-mid2"></div>
            <label for="position" class="placeholder">Stanowisko:</label>
            <ErrorMessage name="position" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field name="positiontype" as="select" class="input" placeholder="">
              <option value="">Wybierz rodzaj stanowiska</option>
              <option value="1">Nauczyciel</option>
              <option value="2">Kierownicze</option>
              <option value="3">Administracyjno-biurowe</option>
              <option value="2">Inżynieryjno-techniczne</option>
              <option value="2">Robotnicze</option>
              <option value="2">BHP</option>
              <option value="2">Inne</option>
            </Field>
            <div class="cut"></div>
            <label for="positiontype" class="placeholder">Rodzaj stanowiska:</label>
            <ErrorMessage name="positiontype" class="error-feedback" />
          </div>
          <div class="nextprev">
            <div class="btn bprev">
              <div @click.prevent="prev()">Cofnij</div>
            </div>
          </div>
          <div v-if="loading">
            <button type="submit" class="submit blink">Trwa rejestracja . . .</button>
          </div>
          <div v-else>
            <button type="submit" class="submit">Zarejestruj się</button>
          </div>
          <div class="statute">Zakładając konto, akceptujesz zapisy Regulaminu szkoleń e-learning W&W Consulting Sp. z
            o.o. Administratorem Twoich danych osobowych jest W&W Consulting Sp. z o.o. z siedzibą w Warszawie przy ul.
            Grochowskiej 306/308 - po więcej informacji czytaj <a href="url">tutaj.</a>
          </div>
          <br>
          <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
            {{ message }}
          </div>
          <div v-else><br></div>
          <br> <br>
        </div>
      </div>
      <div v-if="successful">
        Udało się zarejestrować użytkownika {{ message.firstname }} {{ message.lastname }}.
        Na adres emailowy {{ message.email }} wysłano linka do potwierdzenia rejestracji.
        Użytkownik ma 24 godziny na potwierdzenie rejestracji, po tym czasie konto zostanie usunięte.
        Rejestracje będzie trzeba powtórzyć na nowo.
      </div>
    </div>
  </Form>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
//import ProgressBar from 'vue-simple-progress';
import * as yup from "yup";

export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const today = new Date();
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(100, "Must be maximum 100 characters!"),
      username: yup
        .string()
        .required("Username is required!")
        .min(3, "Must be at least 3 characters!")
        .max(50, "Must be maximum 50 characters!"),
      lastname: yup
        .string()
        .required("User last name is required!")
        .min(3, "Must be at least 3 characters!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 50 characters!"),
      password2: yup
        .string()
        .required("Retype password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 50 characters!"),
      borndate: yup
        .string()
        .max(today, "Borndate is in the future !")
        .required("Borndate is required!"),
      company: yup
        .string()
        .required("Company is required!"),
      taxnumber: yup
        .string()
        .required("Taxnumber is required!"),
      address: yup
        .string()
        .required("Address is required!"),
      zipcode: yup
        .string()
        .required("Zipcode is required!"),
      city: yup
        .string()
        .required("City is required!"),
      position: yup
        .string()
        .required("Position is required!"),
      positiontype: yup
        .string()
        .required("Position type is required!"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
      step: 1,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    prev() {
      this.step--;
    },
    next() {
      this.step++;
    },
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.message = data;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>

progress {
    position: relative;
    width: 100%;
    height: 14px;
    border-radius: 7px;
    /*overflow: hidden;
    //text-align: center ;
     background: crimson; */
}

    progress::-webkit-progress-bar {
        background-color: #DEE5E6;
    }

    progress::-webkit-progress-value {
        background-color: #0132F5;
    }

.progress-label {
    font-weight: 200;
    color: #8B979A;
    position: relative;
}

    .progress-label::after {
        /* content: "75%"; */
        position: absolute;
        top: 0;
        right: 0;
    }

.error-feedback {
  color: red;
  margin-left: 150px;
}
</style>
