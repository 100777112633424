import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import LoginByEmail from "./components/LoginByEmail.vue";
import Register from "./components/Register.vue";
import Password from "./components/Password.vue";
import RegisterConfirm from "./components/RegisterConfirm.vue";
import About from "./components/About.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const BoardWork = () => import("./components/BoardWork.vue")
const ToDoItem = () => import("./components/ToDoItem.vue")
const Employye = () => import("./components/Employee.vue")
const Company = () => import("./components/Company.vue")
const Compa = () => import("./components/Compa.vue")
const Course = () => import("./components/Course.vue")
const AdminTemplate = () => import("./components/template/AdminTemplate.vue")
const routes = [
  {
    path: '/admin2',
    name: 'dashboard',
    component: AdminTemplate,
    children: [{
      path: "/company",
      name: "company",
      // lazy-loaded
      component: Company,
    },]
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/loginbyemail",
    component: LoginByEmail,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/password",
    component: Password,
  },
  {
    path: "/registerconfirm",
    component: RegisterConfirm,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  {
    path: "/work",
    name: "work",
    // lazy-loaded
    component: BoardWork,
  },
  {
    path: "/todoitem",
    name: "todoitem",
    // lazy-loaded
    component: ToDoItem,
  },
  {
    path: "/employee",
    name: "employee",
    // lazy-loaded
    component: Employye,
  },
  {
    path: "/compa",
    name: "compa",
    // lazy-loaded
    component: Compa,
  },
  {
    path: "/course",
    name: "course",
    // lazy-loaded
    component: Course,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;