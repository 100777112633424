import axios from 'axios';
import authHeader from './auth-header';
//'http://localhost:5239/api/Authentication/'
const API_URL =  process.env.VUE_APP_BASE_API_URL;

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'Authentication/login1', {
        username: user.username,
        password: user.password
      }, { headers: authHeader() })
      .then(response => {
        if (response.data.token) 
        {
          localStorage.setItem('user', JSON.stringify(response.data));
          //TokenService.setUser(response.data);
        }
        return response.data;
      });
  }
  loginbycode(user) {
    return axios
      .post(API_URL + 'Authentication/loginbycode', {
        code: user
      }, { headers: authHeader() })
      .then(response => {
        if (response.data.token) 
        {
          localStorage.setItem('user', JSON.stringify(response.data));
          //TokenService.setUser(response.data);
        }
        return response.data;
      });
  }
  confirmbycode(user) {
    return axios
      .post(API_URL + 'Authentication/confirmbycode', {
        code: user
      }, { headers: authHeader() })
      .then(response => {
        if (response.data.token) 
        {
          localStorage.setItem('user', JSON.stringify(response.data));
          //TokenService.setUser(response.data);
        }
        return response.data;
      });
  }
  email(user) {
    //let redirect_uri = window.location.protocol + "//" + window.location.host;
    return axios
      .post(API_URL + 'Authentication/loginbyemail', {
        email: user.email
      });
  }

  logout() {
    localStorage.removeItem('user');
    //TokenService.removeUser();
  }
  register(user) {
    return axios
    .post(API_URL + 'Authentication/register', {
      username: user.username,
      email: user.email,
      password: user.password,
      userlastname: user.lastname,
      birthdate: user.borndate,
      taxnumber: user.taxnumber,
      company: user.company,
      address: user.address,
      zipcode: user.zipcode,
      city: user.city,
      position: user.position,
      typeposition: user.positiontype
    });
  }
}

export default new AuthService();
