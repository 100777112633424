<template>
  <div id="app">
    <!-- <NavBar></NavBar> -->
    <TopNav></TopNav>
    <div class="container">
      <router-view />
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
//import NavBar from "./components/NavBar";
//import TopNav from './components/TopNav.vue';
//import authService2 from "./services/authService";
//import authService from "./services/auth.service";
import Footer from './components/Footer';
import TopNav from "./components/TopNav.vue";
export default {
    components: {
    Footer, TopNav
  },
  created() {
    var urlSearchParams = new URLSearchParams(window.location.search);
    var params = Object.fromEntries(urlSearchParams.entries());
    if (params.login) {
      this.handleLoginByCode(params.login)
      // authService.loginbycode(params.login)
      //   .then(() => {
      //     //if(currentUser())
      //     //commit('loginSuccess', user);
      //     this.$router.push("/home");
      //     //this.$router.refresh();
      //   })
      //   .catch(() => {
      //     this.hasValidationError = true;
      //     //this.$router.refresh();
      //     this.$router.push("/login");
      //     //this.$setBusy(false);
      //   });
    }
    if (params.register) {
      this.handleConfirmByCode(params.register)
    }
    if (params.redirect) {
      var returnURL = localStorage.getItem("redirectUri");
      var item = { path: returnURL, query: params };
      if (returnURL) this.$router.push(item);
      else this.$router.push("/");
    }
  },
  methods: {
    handleLoginByCode(user) {
      this.loading = true;
      this.$store.dispatch("auth/loginbycode", user).then(
        () => {
          // this.$router.push("/profile");
          // this.$router.go();
          // this.$router.push("/home");
          // this.$router.refresh();
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.$router.push("/loginbyemail");
        }
      );
    },
    handleConfirmByCode(user) {
      this.loading = true;
      this.$store.dispatch("auth/confirmbycode", user).then(
        () => {
          //this.$router.refresh();
          this.$router.push("/profile");
          //this.$router.push("/home");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  }
};
</script>
