<template>
  <Form @submit="handleLogin" :validation-schema="schema">
    <div class="loginform">
      <h5><br></h5>
      <div class="subtitle">Zaloguj się</div>
      <div class="input-container ic2">
        <!-- <input id="email" class="input" type="text" placeholder=" "/> -->
        <Field id="email" name="username" type="text" class="input" placeholder="" />
        <div class="cut"></div>
        <label for="email" class="placeholder">Wpisz swój adres e-mail:</label>
        <ErrorMessage name="username" class="error-feedback" />
      </div>
      <div class="input-container ic2">
        <Field name="password" type="password" class="input" placeholder="" />
        <div class="cut cut-short"></div>
        <label for="password" class="placeholder">Hasło:</label>
        <ErrorMessage name="password" class="error-feedback" />
      </div>

        <router-link to="/about" class="flink">
          zapomniałeś hasła ?
        </router-link>

      <div v-if="loading">
        <button type="submit" class="submit blink">Trwa logowanie . . .</button>
      </div>
      <div v-else>
        <button type="submit" class="submit">Zaloguj się</button>
      </div>
      <h5><br></h5>
      <div class="form-group">
        <div v-if="message">
          {{ message }}
        </div>
      </div>
      <div class="l11">lub</div>
      <router-link to="/loginbyemail" class="btn">Zaloguj się przez e-mail</router-link>
      <h5><br></h5>
      <h5><br></h5>
      <div class="l11">lub</div>
      <router-link to="/register" class="btn">Zarejestruj się</router-link>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Wprowadź e-mail !"),
      password: yup.string().required("Wprowadź hasło !"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/profile");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>

.flink {
  color: #007BFF;
  text-decoration: none;
}
.error-feedback {
  color: red;
  margin-left: 100px;
}
</style>
