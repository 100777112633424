import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";/* 
import "bootstrap"; */
import "./fonts/inter/inter.css";
import "./styles/card.css";
import "./styles/global.css";
//import "./styles/footer.css";
//import "./styles/todolist.css";

createApp(App)
  .use(router)
  .use(store)
  //.component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
