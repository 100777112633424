<template>
  <Form @submit="handlePassword" :validation-schema="schema">
    <div class="regform">
      <div v-if="!successful">
        <div v-show="step === 1">
          <div class="input-container ic2">
            <Field name="password" type="password" class="input" placeholder="" />
            <div class="cut cut-short"></div>
            <label for="password" class="placeholder">Nowe hasło:</label>
            <ErrorMessage name="password" class="error-feedback" />
          </div>
          <div class="input-container ic2">
            <Field name="password2" type="password" class="input" placeholder="" />
            <div class="cut cut-mid2"></div>
            <label for="password2" class="placeholder">Powtórz hasło:</label>
            <ErrorMessage name="password2" class="error-feedback" />
          </div>
          <div v-if="loading">
            <button type="submit" class="submit blink">Trwa zmiana hasła . . .</button>
          </div>
          <div v-else>
            <button type="submit" class="submit">Zmień hasło</button>
          </div>
          <br>
          <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
            {{ message }}
          </div>
          <div v-else><br></div>
          <br> <br>
        </div>
      </div>
      <div v-if="successful">
        Udało się zmienić hasło użytkownika {{ message.firstname }} {{ message.lastname }}.
        Zaloguj się na nowo.
      </div>
    </div>
  </Form>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Password",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    //const today = new Date();
    const schema = yup.object().shape({
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 50 characters!"),
      password2: yup
        .string()
        .required("Retype password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 50 characters!")
    });
    return {
      successful: false,
      loading: false,
      message: "",
      schema,
      step: 1,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    handlePassword(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      this.$store.dispatch("auth/password", user).then(
        (data) => {
          this.message = data;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
progress {
  position: relative;
  width: 100%;
  height: 14px;
  border-radius: 7px;
  /*overflow: hidden;
    //text-align: center ;
     background: crimson; */
}

progress::-webkit-progress-bar {
  background-color: #DEE5E6;
}

progress::-webkit-progress-value {
  background-color: #0132F5;
}

.progress-label {
  font-weight: 200;
  color: #8B979A;
  position: relative;
}

.progress-label::after {
  /* content: "75%"; */
  position: absolute;
  top: 0;
  right: 0;
}

.error-feedback {
  color: red;
  margin-left: 150px;
}
</style>
