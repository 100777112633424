<template>
  <nav class="top-nav">
    <div class="logo" @click="home">
      <!-- <img src="@/assets/logo.png" alt="Logo"> -->
      <img :src="getLogo('8' + '.svg')" alt="" />
    </div>
    <div class="nav-links" :class="{ open: isOpen }">
      <div v-if="!currentUser">
        <button class="contact-button" @click="closeMenuAndContact">Skontaktuj się z nami</button>
      </div>
      <div v-else>
        <div class="user-menu" @click="toggleUserDropdown">
        <img class="user-icon" src="@/assets/L48.svg" alt="User Icon" />
        <span>Witamy {{ currentUser.userName }} <i class="arrow-down"></i></span>
        <div class="dropdown user-dropdown" v-if="isUserDropdownOpen">
          <a href="#" @click="profile">Mój profil</a>
          <hr>
          <a href="#" @click="home">Szkolenia</a>
        </div>
      </div>
      </div>
      <div v-if="!currentUser">
        <a href="#" @click="closeUserMenuAndLogout">Zaloguj się</a>
      </div>
      <div v-else>
        <a href="#" @click="closeUserMenuAndLogout">Wyloguj się</a>
      </div>
      <div class='lang'>
        <label for="lang">język</label>
        <select name="languages" id="lang" value="PL">
          <option value="PL">polski</option>
          <option value="EN">English</option>
        </select>
      </div>
    </div>
    <button class="hamburger" @click="toggleMenu">
      <span v-if="!isOpen" class="bar"></span>
      <span v-if="!isOpen" class="bar"></span>
      <span v-if="!isOpen" class="bar"></span>
      <span v-if="isOpen" class="bar"></span>
      <span v-if="isOpen" class="bar1"></span>
      <span v-if="isOpen" class="bar2"></span>
    </button>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      isUserDropdownOpen: false,
      isLanguageDropdownOpen: false,
      userName: 'Jan Kowalski'
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    getLogo(img) {
      return img ? require(process.env.VUE_APP_IMAGE_LOGO) : "";
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
      if (!this.isOpen) {
        this.isUserDropdownOpen = false;
        this.isLanguageDropdownOpen = false;
      }
    },
    closeMenu() {
      this.isOpen = false;
      this.isUserDropdownOpen = false;
      this.isLanguageDropdownOpen = false;
    },
    closeUserMenu() {
      //this.isUserDropdownOpen = false;
      this.isOpen =false;
      //this.closeMenu(); // Zamyka całe menu, w tym user-dropdown
    },
    closeUserMenuAndLogout() {
      this.isUserDropdownOpen = false;
      this.closeMenu(); // Zamyka całe menu, w tym user-dropdown
      this.logout();
    },
    toggleUserDropdown() {
        this.isUserDropdownOpen = !this.isUserDropdownOpen;
        this.isLanguageDropdownOpen = false;
    },
    toggleLanguageDropdown() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
      this.isUserDropdownOpen = false;
    },
    closeMenuAndContact() {
      this.closeMenu();
      this.contactUs();
    },
    contactUs() {
      this.$router.push('/about');
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    home() {
      this.isOpen =false;
      this.$router.push('/home');
    },
    profile() {
      this.isOpen =false;
      this.$router.push('/profile');
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  position: relative;
}

.logo img {
  height: 60px;
}

.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-links.open {
  display: block;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  background: #333;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.nav-links a, .contact-button {
  color: #fff;
  text-decoration: none;
  margin: 10px 0;
  padding: 10px 15px;
  text-align: center;
}

.contact-button {
  background: none;
  border: 1px solid #fff;
  cursor: pointer;
  height: 43px;
  width: 182px;
  border-radius: 22px;
  padding: 10px 10px;
  font-family: 'Inter', sans-serif, Arial;
  font-size: 16px;
  font-weight: 300;
}
.lang {
  font-family: 'Inter', sans-serif, Arial;
  font-size: 16px;
  font-weight: 300;
  }
.lang select {
  font-family: 'Inter', sans-serif, Arial;
  font-size: 16px;
  font-weight: 300;
 }
.actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-menu, .dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif, Arial;
  font-size: 16px;
  font-weight: 300;
}

.user-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.arrow-down {
  margin-left: 5px;
}

.dropdown-content, .dropdown {
  position: absolute;
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  background: #444;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.dropdown-content a, .dropdown a {
  padding: 2px 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  width: 100%;
}

.dropdown-content a:hover, .dropdown a:hover {
  background: #555;
}

.dropdown hr {
  width: 100%;
  border: 0;
  border-top: 1px solid #fff; /* Zmieniony kolor linii na biały */
  margin: 5px 0;
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  border-radius: 1px;
}

.bar1 {
  width: 15px;
  height: 3px;
  background-color: #fff;
  border-radius: 1px;
}

.bar2 {
  width: 5px;
  height: 3px;
  background-color: #fff;
  border-radius: 1px;
}
.close {
  font-size: 24px;
  font-weight: bold;
}

.user-dropdown {
  width: 260px;
  height: 118px;
}

@media (max-width: 768px) {
  .logo img {
  height: 40px;
}
  .nav-links {
    display: none;
  }
  .nav-links.open {
    display: flex;
  }
  .hamburger {
    display: flex;
  }
  .actions {
    display: none;
  }
  .contact-button {
    border: none; /* Usunięcie border w trybie mobilnym */
    height: 32px;
    margin: 0 0;
  }
  .lang {
    background: #333;
    color: #fff;
  }
  .lang select {
    background: #333;
    color: #fff;
  }
}
</style>
